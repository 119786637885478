.landingSection {
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 150px;
  border-bottom: 2px solid #4A8692;
}
.landingSection h1 {
  font-size: 5em;
  font-weight: 300;
  color: rgb(168, 166, 152);
}
.landingSection p {
  color: #4A8692;
}
.landingSection span {
  display: block;
}
.landingSection img { 
  width: 40%;
}

@media screen and (max-width: 1000px) {
  .landingSection img {
    position: absolute;
    z-index: -1;
    opacity: 0.2;
    width: 60%;
    right: 0;
  }
}
@media screen and (max-width: 750px) {
  .landingSection h1 {
    font-size: 4em;
  }
}
@media screen and (max-width: 450px) {
  .landingSection h1 {
    font-size: 3em;
  }
}