.carousel {
}
.grid {

  margin: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 80px;
  flex-wrap: wrap;

}