.heading {
  align-items: center;
  margin-bottom: 0;
  padding-bottom: 0;
}

.hinweis {
  opacity: 0.2;
  text-align: center;
  margin-bottom: 80px;
}

.carousel {
  width: fit-content;
  display: flex;
  position: relative;
  transition: left, 0.2s ease;
}

.dot {
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
}
.caretLeft, .caretRight {
  cursor: pointer;
}
.caretLeft {
  margin-right: 50px;
}
.caretRight {
  margin-left: 50px;
}

.carouselController {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  gap: 15px;
}

@media screen and (max-width: 1000px) {
  .carouselController {
    gap: 10px;
  }
  .caretLeft, .caretRight {
    margin: 0;
  }
}