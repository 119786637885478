.wrapper {
  position: relative;
  height: 500px;
  transition: width 0.2s ease 0.1s;
}
.projectCard {
  right: 0;
  width: 100%;
  position: absolute;
  cursor: pointer;
  border: solid 1px #DFEBED;
  border-radius: 5px;
}

.projectCard .description {
  width: 100%;
  padding: 50px;
  margin-top: 50px; 
  max-height: calc(100% - 100px);
  overflow-y: auto;
  
  margin-inline: auto;
  opacity: 0;
  transition: opacity 0.2s ease;
  background-color: #DFEBED; 
}
.description::-webkit-scrollbar {
  display: none;
}

.projectCard:hover .description, .projectCard:focus .description {
  opacity: 1;
}

.projectCard img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  position: absolute;
  z-index: -1;
  transition: filter 0.2s ease;
}
.description h3 {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 10px;
  color: black;
  position: relative;
  z-index: 2;
}
.description p {
  margin-top: 20px;
  text-align: center;
  position: relative;
}
.projectCard:hover img, .projectCard:focus img {
  filter: blur(2px);
}

