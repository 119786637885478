
.teamSection h2 {
  font-weight: 400;
  color: #4A8692;
}
.teamSection p {
  color: #000000;
  text-decoration: none;
}

.teamimage {
  margin-block: 100px;
  width: 100%;
  object-fit: cover;
  
}

.teamSection span {
  display: block;
}

.logo {
  width: 300px;
  aspect-ratio: 1 / 1;
  float: right;
  margin: 0;
}
@media screen and (max-width: 750px) {
  .teamSection h1 {
    font-size: 4em;
  }
}
@media screen and (max-width: 450px) {
  .teamSection h1 {
    font-size: 3em;
  }
}