.projectsSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.projectsSection h2 {
  font-weight: 400;
  color: #4A8692;
  margin-top: 50px;
}
.projectsSection p {
  color: #000000;
  text-decoration: none;
}