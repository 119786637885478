.header {
  position: sticky;
  z-index: 99999999;
  top: 0;
  width: 100%;
  padding: 40px var(--page-padding) 15px var(--page-padding);
  display: flex;
  justify-content: space-between;
  transition: background-color 0.4s ease-in-out;
}

.header b {
  color: #4A8692;
}

.header ul {
  display: flex;
  gap: 50px;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 1000px) {
  .header {
    flex-direction: column;
    gap: 10px;
  }
}


@media screen and (max-width: 340px) {
  .header ul {
    gap: 30px;
  }
}

