.footer {
  margin-top: 100px;
  background-color: whitesmoke;
  padding-block: 100px;
  padding-inline: 10%;
  text-align: center;
}
.footer p {
  font-size: 1em;
}
.footer h1 {
  font-size: 1.5em;
}
.footer button {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}