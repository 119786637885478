
/* globale Datei für das Styling der Webseite */

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-Medium.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-Thin.ttf");
  font-weight: 100;
}


* {
  /* reset */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  scroll-padding-top: 200px;
}

:root {
  --page-padding: 200px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
}
body main {
  overflow-x: hidden;
  padding-inline: var(--page-padding);
}


h2 {
  font-size: 2em;
  padding-top: 2em;
  padding-bottom: 1em;
  color: #4A8692;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, b, i {
  font-size: 1.3em;
  font-weight: 500;
}
a {
  text-decoration: none;
  color: #4A8692;
}

@media screen and (max-width: 1300px) {
  :root {
    --page-padding: 100px;
  }
}
@media screen and (max-width: 1100px) {
  :root {
    --page-padding: 10%;
  }
}